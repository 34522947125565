import React from 'react';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from "@material-ui/core/IconButton";
import Favorite from "@material-ui/icons/Favorite";

import { VerifiedDisplay } from '../cardComponents/verifiedDisplay';
import { PostingLabel } from './postingLabel';
import { Link } from "gatsby";

/**
 * List item for posting record
 * @param {*} props 
 * @author BrianCastor
 */
export default function PostingListItem(props) {
    const record = props.record;

    if (!record) {
        return (null)
    }

    return (
        <ListItem button component={Link} to={'/posting?id=' + record.Id}>
            <ListItemText
                primary={record.AccountId__r ? record.AccountId__r.Name : ''}
                secondary={(
                    <React.Fragment>
                        <PostingLabel/>
                        <span>{record.Drupal_HOS_Post_Title__c}</span>
                        <VerifiedDisplay dateVerified={record.Original_Post_Date__c} />
                    </React.Fragment>)}
            />
            <ListItemSecondaryAction>
                <IconButton
                    edge="end"
                    aria-label="unfavorite"
                    color="primary"
                    onClick={() => props.unFavoriteRecord(record.Id)}
                >
                    <Favorite style={{ fontSize: '20px' }} />
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    )
};