import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Favorites from "../components/favorites"
import Container from '@material-ui/core/Container';

const FavoritesPage = () => (
  <Layout>
    <Seo title="Favorites" />
    <Container maxWidth="sm" style={{ paddingTop: '10px' }}>
      <Favorites />
    </Container>
  </Layout>
)

export default FavoritesPage
