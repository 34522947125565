import React from "react"
import { useState, useContext } from "react"

import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Typography from "@material-ui/core/Typography"
import Popover from "@material-ui/core/Popover"
import IconButton from "@material-ui/core/IconButton"

//Icons
import Favorite from "@material-ui/icons/Favorite"
import ShareIcon from "@material-ui/icons/Share"
import EmailIcon from "@material-ui/icons/Email"
import FacebookIcon from "@material-ui/icons/Facebook"
import RedditIcon from "@material-ui/icons/Reddit"
import TwitterIcon from "@material-ui/icons/Twitter"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"

import { globalContext } from "../../GlobalContext"

import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share"

import { VerifiedDisplay } from "../cardComponents/verifiedDisplay"
import { Link } from "gatsby"
import { AccountLabel } from "./accountLabel"

/**
 * List item for account record
 * @param {*} props
 * @author BrianCastor
 */

export default function AccountListItem(props) {
  const [anchorEl, setAnchorEl] = useState(null)
  const record = props.record
  console.log(record)

  const context = useContext(globalContext)

  if (!record) {
    return null
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <ListItem button component={Link} to={"/account?id=" + record.Id}>
      <ListItemText
        primary={record.Name}
        secondary={
          <React.Fragment>
            <AccountLabel />
            <VerifiedDisplay dateVerified={record.Date_Verified_Services__c} />
          </React.Fragment>
        }
      />
      <ListItemSecondaryAction
        style={{ display: "flex", alignItems: "start", paddingTop: "20px" }}
      >
        {context.isRegistered() && (
          <IconButton aria-label="share" color="primary" onClick={handleClick}>
            <div
              style={{ display: "flex", flexDirection: "column", alignItems: "center",
              }}
            >
              <ShareIcon style={{ fontSize: "20px", paddingRight: "5px" }} />
              <Typography>share</Typography>
            </div>
          </IconButton>
        )}
        <IconButton edge="end" aria-label="unfavorite" color="primary" onClick={() => props.unFavoriteRecord(record.Id)}>
          <div
            style={{ display: "flex", flexDirection: "column", alignItems: "center",
              textAlign: "justify",
            }}
          >
            <Favorite style={{ fontSize: "20px", paddingLeft: "5px" }} />
            {/* <Typography>unfavorite</Typography> */}
          </div>
        </IconButton>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "center",
          }}
        >
          {/* Align share buttons vertically */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "flex-start",
              textAlign: "justify",
            }}
          >
            <EmailShareButton
              url={"https://hopeonesource.me/account?id=" + record.Id}
              subject={record.Name}
              body={
                "I found this verified service through hopeonesource.org that you might be interested in."
              }
              separator={"\n\n"}
            >
              {/* Align icon and text horizontally */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "5px",
                  justifyContent: "left",
                }}
              >
                <EmailIcon htmlColor="#4384F3" />
                <Typography>Email</Typography>
              </div>
            </EmailShareButton>
            <FacebookShareButton
              url={"https://hopeonesource.me/account?id=" + record.Id}
              quote={
                "Get the help you need with services from " +
                record.Name +
                " verified by Hope One Source"
              }
              hashtag={"#hopeonesource"}
            >
              {/* Align icon and text horizontally */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "5px",
                }}
              >
                <FacebookIcon htmlColor="#1A73E3" />
                <Typography>Facebook</Typography>
              </div>
            </FacebookShareButton>
            <RedditShareButton
              url={"https://hopeonesource.me/account?id=" + record.Id}
              title={record.Name + " Services Verfied by Hope One Source"}
            >
              {/* Align icon and text horizontally */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "5px",
                }}
              >
                <RedditIcon htmlColor="#FF4500" />
                <Typography>Reddit</Typography>
              </div>
            </RedditShareButton>
            <TwitterShareButton
              url={"https://hopeonesource.me/account?id=" + record.Id}
              title={
                "Get the help you need with services from " +
                record.Name +
                " verified by Hope One Source"
              }
              via={"hopeonesource"}
            >
              {/* Align icon and text horizontally */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "5px",
                }}
              >
                <TwitterIcon htmlColor="#1A8CD8" />
                <Typography>Twitter</Typography>
              </div>
            </TwitterShareButton>
            <WhatsappShareButton
              url={"https://hopeonesource.me/account?id=" + record.Id}
              title={record.Name}
              separator={"\n\n"}
            >
              {/* Align icon and text horizontally */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "5px",
                }}
              >
                <WhatsAppIcon htmlColor="#51CC5C" />
                <Typography>WhatsApp</Typography>
              </div>
            </WhatsappShareButton>
          </div>
        </Popover>
      </ListItemSecondaryAction>
    </ListItem>
  )
}
