import React from 'react';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import {getTypeOfSalesforceRecord} from '../utils';

import { Link } from "gatsby";

//Generic API Interface
import { globalContext } from "../GlobalContext"
import API from '../API';

//Sub components
import { ErrorDisplay } from './errorDisplay';
import AccountListItem from './accounts/accountListItem';
import PostingListItem from './postings/postingListItem';
import { LoadingDisplay } from './loadingDisplay';

/**
 * Display for a user to view their favorited postings/service providers. Can unlike or click into a favorite on this page
 * Current merges posting and service provider likes into 1 list
 * @param {*} props 
 * @author BrianCastor
 */
export default function Favorites(props) {
    const context = React.useContext(globalContext);

    const [favorites, setFavorites] = React.useState([]);
    const [error, setError] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        setLoading(true);
        API.getFavorites(context.favoritedPostingIds).then(favs => {
            setFavorites(favs);
            setLoading(false);
        }).catch(err => {
            setError(err);
            setLoading(false);
        });
    }, []);

    //Redirect to / if user is not signed in
    if (!context.isRegistered()) {
        context.navigateSafe('/');
        return(null);
    }

    if (loading) {
        return (<LoadingDisplay/>)
    }

    if (error) {
        return (<ErrorDisplay error={error} />)
    }

    function unFavorite(recordId, recordType) {
        setFavorites(favorites.filter(record => record.Id !== recordId));
        context.unFavoritePosting(recordId, recordType);
    }

    return (
        <div style={{ marginBottom: '100px', backgroundColor: 'white', padding: '2px 15px 15px 15px' }}>
            <Grid container justify="space-between" alignItems="center">
                <Grid item>
                <h2>Favorites</h2>
                </Grid>
                <Grid item>
                <Button color="inherit" variant="contained" component={Link} to="/home">Navigate Home</Button>
                </Grid>
            </Grid>
      
            {//Favorites list
                favorites.length > 0 ? (
                    <List>
                        {favorites.map((record, index) => {
                            return (<React.Fragment key={record.Id}>
                                {getTypeOfSalesforceRecord(record) === 'Account' ?
                                    (<AccountListItem record={record} unFavoriteRecord={(id) => unFavorite(id, 'Account')} />)
                                    :
                                    (<PostingListItem record={record} unFavoriteRecord={(id) => unFavorite(id, 'Posting__c')}/>)
                                }
                                <Divider />
                            </React.Fragment>)
                        })}
                    </List>
                )
                    :
                    //Empty state
                    (
                        <div style={{ textAlign: 'center', width: '100%', marginTop: '50px' }}>
                            <ThumbsUpDownIcon style={{ fontSize: 80, color: 'grey' }} />
                            <h3 style={{ color: 'grey' }}>No favorites found</h3>
                            <p style={{ color: 'grey' }}>When you favorite postings or service providers in your matches, they'll show up here in your favorites list. Get swiping!</p>
                            <Button
                                variant="contained"
                                color="primary"
                                component={Link}
                                to="/swipe"
                            >
                                View your matches
                        </Button>
                        </div>
                    )
            }
        </div>
    )
}